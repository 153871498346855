import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import Field from '../../../PageBuilder/Field';
import { FaArrowUp } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import SearchPage from '../../../SearchPage/SearchPageWithMap';
import { SearchOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const SignupLink = () => {
  return (
    <div onClick={() => { 
      if(typeof localStorage !== 'undefined')
        localStorage.setItem('userType', false); 
      }}>
      <NamedLink name="SignupPage" className={css.topbarLink} >
        <span className={css.topbarLinkLabel} >
          <FormattedMessage id="TopbarDesktop.signup" />
        </span>
      </NamedLink>
    </div>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const BecomeHostFamilyLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      // name="AuthenticationEsianPage"
      name="EsianSignupPage"
      params={{ tab: 'signup' }}

    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.becomeAHostFamily" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const isAdmin = currentUser?.attributes?.profile.publicData?.isAdmin == true ? true : false;
  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {isAdmin ?

          <MenuItem key="PartnerPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
              name="PartnerPage"
              params={{ tab: 'partners' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.PartnerPage" />
            </NamedLink>
          </MenuItem>

          : <MenuItem key="logout">

          </MenuItem>}
        {isAdmin ?

          <MenuItem key="SettingPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
              name="PartnerPage"
              params={{ tab: 'setting' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="SettingPage.ordersTabTitle" />
            </NamedLink>
          </MenuItem>


          : <MenuItem key="logout">

          </MenuItem>}

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>

      </MenuContent>
    </Menu >
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
    isLandingPage = false,
    isSearchPage,   
    isFirstPage,
  } = props;

  const review_status = props?.currentUser?.attributes?.profile?.publicData?.review_status;
  const isUserVerified = props?.currentUser?.attributes?.profile?.publicData?.isUserVerified;

  const userType = currentUser?.attributes?.profile?.publicData?.userType;

  const [mounted, setMounted] = useState(false);
  const [location, setSearchLocation] = useState(null);
  const [listingtype, setListingtype] = useState(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const serachclick = (e) => {
    setSearchLocation(e);
  }
  const selectedListingtype = (e) => {
    setListingtype(e.target.value);
  }


  const onSearchClick = (e) => {
    if (e.listingtype == null) {
      e.listingtype = "All"
    }
    onSearchSubmit(e)
  }
  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const history = useHistory();
  const onWIHCommunity = (value) => {
    window.location.replace(value);
  }

  const wihCommunity = (
    <Button className={css.topbarCustomNav} onClick={() =>  { window.open('https://world-like-home.community/', '_blank'); }}>
      <FormattedMessage id="TopbarDesktop.wihCommunity" />
    </Button>
  );

  const becomeAHostFamily = (
    <NamedLink
      className={css.topbarLink}
      name="EsianSignupPage"
      params={{ tab: 'signup' }}

    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.becomeAHostFamily" />
      </span>
    </NamedLink>
  );

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;
  var listingTypes = []
  if (!listingTypes.find(x => x.value == "All")) {
    listingTypes.push({ value: 'All', label: 'All', listingType: 'All' });
  }
  listingTypes = [...listingTypes, ...props.config.listing.listingTypes];

  const isCookiesEnabled = 
  Cookies?.get("isCookiesEnabled") || false;
  // const [isDialogOpen, setDialogOpen] = useState(!isCookiesEnabled);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onCookiesClick = () => {
    Cookies.set("isCookiesEnabled", true, { expires: 10 });
    setDialogOpen(false);
  }

  const [showHeaderSearch, setShowHeaderSearch] = useState(false);
  const [showHeroSearch, setShowHeroSearch] = useState(true);
  const [showDetailedSearch, setShowDetailedSearch] = useState(false);
  const [showFilterSearch, setShowFilterSearch] = useState(false);

  const updateDisplayFilter = (value) => {
    setShowFilterSearch(value);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200;
  
      if (window.scrollY <= scrollThreshold) {        
        if (!isFirstPage) {
          setShowFilterSearch(false);
        } else {          
            setShowHeaderSearch(false);
        }
        setShowHeroSearch(true);
        setShowDetailedSearch(false);
      }
      else{
        if (isSearchPage) {
        setShowFilterSearch(false);
        } else {          
          setShowHeaderSearch(true);
          setShowFilterSearch(false);
        }
        setShowHeroSearch(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDetailedSearchClick = () => {   
    if (isSearchPage) {
      setShowFilterSearch((prevState) => !prevState);
    } else {
      setShowDetailedSearch((prevState) => !prevState);
    }
  };

  return (
    <>
      <nav className={isLandingPage == false ? classes : null}>
        {isLandingPage == false ? (
          <>
            <div>
              <LinkedLogo
                className={css.logoLink}
                layout="desktop"
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
                linkToExternalSite={config?.topbar?.logoLink}
              />{' '}
            </div>
            <div className={css.searchContainer}>
              {(showHeaderSearch || !isFirstPage) && (
                <div className="header-search">
                  {!isSearchPage ? (
                    <Tooltip placement="bottom" color='#3a5795' title={<FormattedMessage id="TopbarSearchForm.placeholder" />}>
                      <button className={css.topbarSearch} onClick={handleDetailedSearchClick}>
                        <FormattedMessage id="TopbarSearchForm.placeholder" />
                      </button>
                    </Tooltip>
                  ) : (
                    <div className={css.listingSearch}>
                      <TopbarSearchForm
                        className={css.topbarSearch}
                        desktopInputRoot={css.topbarSearchWithLeftPadding}
                        onSubmit={isLandingPage == false ? onSearchSubmit : serachclick}
                        initialValues={initialSearchFormValues}
                        appConfig={config}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : null}
        <div className={css.menuSection}>
          {isLandingPage == false ? wihCommunity : null}
          {isLandingPage == false && !authenticatedOnClientSide ? becomeAHostFamily : null}
          {isLandingPage == false && authenticatedOnClientSide ? (
            <CustomLinksMenu
              currentPage={currentPage}
              customLinks={customLinks}
              intl={intl}
              hasClientSideContentReady={
                authenticatedOnClientSide || !isAuthenticatedOrJustHydrated
              }
              review_status={review_status}
              isUserVerified={isUserVerified}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          ) : null}

          {isLandingPage == false ? inboxLinkMaybe : null}
          {isLandingPage == false ? profileMenuMaybe : null}
          {isLandingPage == false ? signupLinkMaybe : null}
          {isLandingPage == false ? loginLinkMaybe : null}
        </div>
        {isLandingPage == true ? (
          <div>
            <div className="hero-section">
              {showHeroSearch && (
                <div>
                  <div className={css.heroSectionSearch}>
                    <select name="state" onChange={e => selectedListingtype(e)}>
                      {listingTypes.map(s =>
                        s.listingType != 'All' ? (
                          <option key={s} value={s.listingType}>
                            {intl.formatMessage({
                              id: `CustomExtendedDataField.${s.listingType}`,
                            })}
                          </option>
                        ) : (
                          <option key={s} value={s.listingType} selected>
                            {intl.formatMessage({
                              id: `CustomExtendedDataField.${s.listingType}`,
                            })}
                          </option>
                        )
                      )}
                    </select>
                    <TopbarSearchForm
                      className={classNames(css.searchLink, {
                        [css.takeAvailableSpace]: giveSpaceForSearch,
                      })}
                      desktopInputRoot={css.topbarSearchWithLeftPadding}
                      onSubmit={isLandingPage == false ? onSearchSubmit : serachclick}
                      initialValues={initialSearchFormValues}
                      appConfig={config}
                    />

                    {isLandingPage == false ? null : (
                      <button
                        className={css.ctaButton}
                        onClick={() => {
                          onSearchClick({ location, listingtype });
                        }}
                      >
                        <FormattedMessage id="TopbarDesktop.search" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </nav>

      <div className="search-container">
        {showDetailedSearch && (
          <div className="detailed-search">
            <div className={css.heroSectionSearch}>
              <select name="state" onChange={e => selectedListingtype(e)}>
                {listingTypes.map(s =>
                  s.listingType != 'Host_Family' ? (
                    <option key={s} value={s.listingType}>
                      {intl.formatMessage({
                        id: `CustomExtendedDataField.${s.listingType}`,
                      })}
                    </option>
                  ) : (
                    <option key={s} value={s.listingType} selected>
                      {intl.formatMessage({
                        id: `CustomExtendedDataField.${s.listingType}`,
                      })}
                    </option>
                  )
                )}
              </select>
              <TopbarSearchForm
                className={classNames(css.searchLink, {
                  [css.takeAvailableSpace]: giveSpaceForSearch,
                })}
                desktopInputRoot={css.topbarSearchWithLeftPadding}
                onSubmit={serachclick}
                initialValues={initialSearchFormValues}
                appConfig={config}
              />

              {isLandingPage == true ? null : (
                <button
                  className={css.ctaButton}
                  onClick={() => {
                    onSearchClick({ location, listingtype });
                  }}
                >
                  <FormattedMessage id="TopbarDesktop.search" />
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="search-container">
        {showFilterSearch && (
          <div className="detailed-search">
            <div className={css.heroSectionSearch}>
              <SearchPage
                justDisplayFilters={true}
                updateDisplayFilter={updateDisplayFilter}
              ></SearchPage>
            </div>
          </div>
        )}
      </div>

      <>
        {isLandingPage == false && isDialogOpen && (
          <div className={css.dialogPopupOverlay}>
            <div className={css.dialogPopup}>
              <button className={css.dialogPopupCloseButton} onClick={handleCloseDialog}>
                &times;
              </button>
              <p>
                <FormattedMessage id="TopbarDesktop.cookiesModal" />
              </p>
              <div className={css.cookiesButton}>
                <button
                  className={css.cookiesManager}
                  onClick={() => {
                    onCookiesClick();
                  }}
                >
                  <FormattedMessage id="TopbarDesktop.cookieManager" />
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  ); 
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
